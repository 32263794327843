import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import { StandardLayout } from '../layout/layout'
import * as colors from '../colors.module.css'
import OurFramework from '../components/our-framework'
import OurPrograms from '../components/our-programs'
import OurCurriculum from '../components/our-curriculum'

export default function WhatWeDo(props) {
  return (
    <StandardLayout
      title="What We Do"
      subtitle="Coaches engage youth through fun sports programs infused with educational content that empowers young leaders to grow and develop"
      backgroundColor={colors.navyBackground}
      titleColor={colors.golden}
      img={<StaticImage src="../images/huddle.png" imgStyle={{ objectPosition: '5% 5%' }} loading="eager" alt="Coach and youths huddle" />}
      ogImageSrc="../images/huddle.png"
    >
      <OurPrograms />
      <OurFramework />
      <OurCurriculum />
    </StandardLayout>
  )
}
