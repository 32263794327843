import React from 'react'
import { MediumParagraph, BigParagraph, CardHeader } from '../../typography'
import { FullWidthVerticallyCenteredCard } from '../cards'
import { contents } from './our-framework.module.css'
import * as colors from '../../colors.module.css'
import LeadershipTrack from './leadership-track'



function OurFramework() {
  return (
    <FullWidthVerticallyCenteredCard className={colors.redBackground}>
      <CardHeader className={colors.navy}>Our Framework</CardHeader>
      <div className={contents}>
        <BigParagraph className={colors.white}>We build and empower leaders to level up through our programs</BigParagraph>
        <LeadershipTrack />
      </div>
    </FullWidthVerticallyCenteredCard>
  )
}

export default OurFramework
