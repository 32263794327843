import React from 'react'
import { Link } from 'gatsby'
import * as cls from 'classnames'
import * as styles from './arrow-link.module.css'

export function ArrowLinkRow(arrowLinkProps) {
  return (
    <div className={styles.arrowLinkRow}>
      <ArrowLink
        {...arrowLinkProps}
        className={styles.arrowLink}
      />
    </div>
  )
}

function ArrowLink({ text, to, color, className }) {
  return (
    <Link to={to} className={cls(className, styles.arrowLink)} style={{ color }}>
      {text}
      <div className={styles.arrowContainer}>
      <span className={styles.arrow} style={{ borderLeft: `7px solid ${color}`}} />
      </div>
    </Link>
  )
}

export default ArrowLink
