import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import { FullWidthCard, PictureCard, PictureCardText, PictureCards } from '../cards'
import { CardHeader } from '../../typography'
import * as styles from './our-programs.module.css'
import * as colors from '../../colors.module.css'
import ArrowLink from '../arrow-link'

function OurProgramCard({ logo, picture, color, description, to }) {
  const linkToProgramPage = false

  return (
    <PictureCard img={logo} color={color}>
      <div className={styles.ourProgramCardContents}>
        {picture}
        <PictureCardText className={styles.ourProgramCardText}>
          <p>{description}</p>
          {linkToProgramPage && <ArrowLink
            text="Learn More"
            to={to}
            color={color}
            className={styles.readMoreLink}
          />}
        </PictureCardText>
      </div>
    </PictureCard>
  )
}

function OurProgramCards() {
  return (
    <PictureCards>
      <OurProgramCard
        logo={<img src="/naijacore.svg" alt="naijacore logo" />}
        picture={<StaticImage src="../../images/our-programs-naijacore.png" imgStyle={{ width: '100%' }} className={styles.naijacoreoffset}/>}
        color="#ef4b40"
        description="By incorporating African music and pride with yoga, calisthenics, and dance — NaijaCore is an incredibly fun program that is accessible to all ages, skills, and genders. Team captains lead community events and online live streams."
        to="/naijacore"
        />
      <OurProgramCard
        logo={<img src="/basketball_experience.svg" alt="basketball experience logo" />}
        picture={<StaticImage src="../../images/our-programs-be-team.png" objectPosition="10% 10%" />}
        color="#000000"
        description="We are excited to be implementing the largest youth development through basketball program in Nigeria in partnership with the NBA. Schools in Lagos benefit from clinics, assemblies, and year-end events led by well-trained coaches."
        to="/basketball-experience"
        />
      <OurProgramCard
        logo={<img src="/coach_activation.svg" alt="coach activation logo" />}
        picture={<StaticImage src="../../images/coach_activation3.png" objectPosition="5% 5%" />}
        color="#EF4B40"
        description="Direct training and mentorship helps our youth captains and coaches with the knowledge to not only run our programs, but be successful leaders in their communities. We provide male and female staff with workshops, guidebooks, and one on one support to improve their performance in work and in life."
        to="/coach-activation"
      />
    </PictureCards>
  )
}

const OurProgram = React.forwardRef((props, ref) => (
  <FullWidthCard className={styles.ourImpactSection} ref={ref}>
    <CardHeader className={colors.navy}>Our Programs</CardHeader>
    <OurProgramCards />
  </FullWidthCard>
))

export default OurProgram
